<template>
  <b-table
    :items="mainList"
    :fields="tableFields"
    :busy="onLoadingList"
    class="table-bordered table-hover"
  >
    <template v-slot:table-busy>
      <vcl-table :speed="5" :animate="true" :columns="10" />
    </template>
    <template v-slot:cell(index)="row">
      {{ row.index + 1 }}
    </template>
    <template v-slot:cell(createdAt)="row">
      <span style="display: block">{{ formatDate(row.item.createdAt) }}</span>
    </template>
  </b-table>
</template>

<style>
.break-line {
  white-space: break-spaces;
}
</style>

<script>
import { VclTable } from 'vue-content-loading';
import ApiService from '@/core/services/api.service';
import moment from 'moment';
const thStyleCommon = {
  textAlign: 'center',
  fontWeight: 600,
  color: '#181c32',
  width: '5%',
};

export default {
  props: ['stockCode'],
  components: {
    VclTable,
  },
  data() {
    return {
      mainList: [],
      onLoadingList: false,
      tableFields: [
        {
          key: 'index',
          label: 'STT',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '5%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'createdAt',
          label: 'Ngày hủy',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'createdByName',
          label: 'Người hủy',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-center',
        },
        {
          key: 'typeName',
          label: 'Loại',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-left',
        },
        {
          key: 'reasonName',
          label: 'Lý do',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '15%',
          },
          tdClass: 'text-left break-line',
        },
        {
          key: 'description',
          label: 'Diễn giải',
          sortable: false,
          thStyle: {
            ...thStyleCommon,
            width: '35%',
          },
          tdClass: 'text-left break-line',
        },
      ],
    };
  },
  methods: {
    fetchMainData: async function () {
      // Init request header.
      this.onLoadingList = true;
      ApiService.get(`purchase-order/cancel-reason/${this.stockCode}`)
        .then((response) => {
          const respData = response.data.data;
          this.mainList = respData;
          this.onLoadingList = false;
        })
        .catch(() => {
          this.onLoadingList = false;
        });
    },
    formatDate: function (dateVal) {
      const date = moment(dateVal);
      return date.format('HH:mm:ss DD/MM/yyyy');
    },
  },
  mounted() {
    this.fetchMainData();
  },
};
</script>
